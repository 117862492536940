import { Routes, Route } from 'react-router-dom';
import './App.css';
// App.js
import Home from './Pages/Home';
import Services from './Pages/Services';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Layout from './Pages/Layout';
function App() {
  return (
       <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Home />} /> 
            <Route path="/services" element={<Services />} /> 
            <Route path="/about" element={<About />} /> 
            <Route path="/contact" element={<Contact />} /> 
          </Route>                    
       </Routes>

  );
}

export default App;
